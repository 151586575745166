import React from "react";

import catering from "../../../assets/images/catering pic real official.png";
import aboutusbanner from "../../../assets/images/about us front banner real deal.png";
import award from "../../../assets/images/Aarti Banquet (2).jpg";
import trail3 from "../../../assets/images/trial THREE.svg";
import spacesEquipment from "../../../assets/images/spaces and equipment collage.png";
import decoration from "../../../assets/images/decorations collage.png";
import events from "../../../assets/images/event packages.png";
import garden from "../../../assets/images/garden pic for home page.jpg";
import gallaeryMarriage from "../../../assets/images/front page gallery marriage photo.png";
import frontWeddingPic from "../../../assets/images/front wedding pic.png";
import handpics from "../../../assets/images/pink_flower-table-arrangement.png";

import { Link } from "@tanstack/react-location";
import { AppFooter } from "../components/app-footer";

function Services() {
  const isSmallScreen = window.innerWidth <= 640;
  return (
    <>
      {/* <div>
        <img src={handpics} alt="after header" />
      </div> */}

      <div className="">
        <img
          src={handpics}
          alt="after header"
          className="h-auto"

          // className="mt-[200px] w-full object-cover"
        />
      </div>
      {/* Two Columns */}
      <div className="relative grid md:grid-cols-2 sm:grid-cols-1">
        {/* Background Color Overlay */}
        <div className="absolute inset-0 bg-[#f8f3e8]"></div>

        <div className="relative z-10 pt-24 pb-5 rounded-lg flex flex-col items-center bg-[#f3ebd9] ">
          <div className="">
            <h1 className="md:pb-10 pb-0 md:text-3xl text-center text-xl md:font-bold font-semibold text-[#a54c0e] uppercase md:tracking-widest">
              Catering
            </h1>
          </div>
          {/* Centered container for the lines and image */}

          <p className="leading-10 font-bold px-5 md:px-10">
            Satisfy your taste buds and impress your guests with our exceptional
            catering services. Choose from a wide range of menus or customize
            your own to fit your event's theme and preferences. Whether you're
            hosting an intimate gathering or a grand celebration, our
            sophisticated chefs are dedicated to preparing dishes with
            top-quality ingredients, ensuring a memorable culinary experience
            for you and your guests.
          </p>
        </div>

        {/* Right Column */}
        <div className="relative z-10 rounded-lg flex flex-col justify-center items-center pt-10 md:pt-10 md:px-5 md:pb-5">
          <div className="">
            <img
              src={catering}
              alt="catering"
              className="max-w-full h-[29.68rem]"
            />
          </div>
        </div>
      </div>
      {/*  Second Pragraph  */}

      {/* Two Columns */}
      {/* Right column pic first */}
      {isSmallScreen ? (
        <div className="relative grid md:grid-cols-2 sm:grid-cols-1">
          {/* Background Color Overlay */}
          <div className="absolute inset-0 bg-[#f8f3e8]"></div>

          <div className="relative z-10 pt-24 pb-5 rounded-lg flex flex-col items-center bg-[#f3ebd9] ">
            <div className="">
              <h1 className="md:pb-10 pb-0 md:text-3xl text-center text-xl md:font-bold font-semibold text-[#a54c0e] uppercase md:tracking-widest">
                Spaces and Equipment
              </h1>
            </div>
            {/* Centered container for the lines and image */}

            <p className="leading-10 font-bold px-5 md:px-10">
              Explore the ideal setting for your event with our comprehensive
              spaces and equipment offerings. We provide a range of essentials
              including tables, chairs, and tents to ensure your event runs
              smoothly. Our venue boasts two air-conditioned party halls, each
              spanning 2500 sqft, along with ample parking for 20 vehicles, 13
              rooms, 19 bathrooms, and a full-fledged kitchen. Additionally, our
              17500 sqft yard offers a spacious outdoor setting for your guests
              to enjoy. With a backup generator on-site, you can rest assured
              that your event will go off without a hitch.
            </p>
          </div>
          {/* Right Column */}
          <div className="relative z-10 rounded-lg flex flex-col justify-center items-center pt-10">
            <div className="">
              <img
                src={spacesEquipment}
                alt="spaces and equipment"
                className="max-w-full h-[29.68rem]"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative grid md:grid-cols-2 sm:grid-cols-1">
          <div className="absolute inset-0 bg-[#f8f3e8]"></div>
          {/* Right Column */}
          <div className="relative z-10 rounded-lg flex flex-col justify-center items-center pt-10 md:pb-5">
            <div className="">
              <img
                src={spacesEquipment}
                alt="spaces and equipment"
                className="max-w-full h-[29.68rem]"
              />
            </div>
          </div>
          {/* Background Color Overlay */}
          {/* <div className="absolute inset-0 bg-[#f8f3e8]"></div> */}

          <div className="relative z-10 pt-24 pb-5 rounded-lg flex flex-col items-center bg-[#f3ebd9] ">
            <div className="">
              <h1 className="md:pb-10 pb-0 md:text-3xl text-center text-xl md:font-bold font-semibold text-[#a54c0e] uppercase md:tracking-widest">
                Spaces and Equipment
              </h1>
            </div>
            {/* Centered container for the lines and image */}

            <p className="leading-10 font-bold px-5 md:px-10">
              Explore the ideal setting for your event with our comprehensive
              spaces and equipment offerings. We provide a range of essentials
              including tables, chairs, and tents to ensure your event runs
              smoothly. Our venue boasts two air-conditioned party halls, each
              spanning 2500 sqft, along with ample parking for 20 vehicles, 13
              rooms, 19 bathrooms, and a full-fledged kitchen. Additionally, our
              17500 sqft yard offers a spacious outdoor setting for your guests
              to enjoy. With a backup generator on-site, you can rest assured
              that your event will go off without a hitch.
            </p>
          </div>
        </div>
      )}

      {/* Third Picture and para */}
      {/* Two Columns */}
      <div className="relative grid md:grid-cols-2 sm:grid-cols-1">
        {/* Background Color Overlay */}
        <div className="absolute inset-0 bg-[#f8f3e8]"></div>

        <div className="relative z-10 pt-24 pb-5 rounded-lg flex flex-col items-center bg-[#f3ebd9] ">
          <div className="">
            <h1 className="md:pb-10 pb-0 md:text-3xl text-center text-xl md:font-bold font-semibold text-[#a54c0e] uppercase md:tracking-widest">
              Decoration
            </h1>
          </div>
          {/* Centered container for the lines and image */}

          <p className="leading-10 font-bold  px-5 md:px-10">
            Transform your event space into a breathtaking setting with our wide
            range of decoration services. From elegant tents to stunning floral
            arrangements, sophisticated stages to high-quality landscapes, we
            have everything you need to create an unforgettable ambiance. Let us
            help you bring your vision to life with our expertly crafted
            decorations.
          </p>
        </div>

        {/* Right Column */}
        <div className="relative z-10 rounded-lg flex flex-col justify-center items-center pt-10 md:pb-5">
          <div className="">
            <img
              src={decoration}
              alt="decoration"
              className="max-w-full h-[29.68rem]"
            />
          </div>
        </div>
      </div>

      {/* Fourth para and pics */}
      {/* Two Columns */}
      {isSmallScreen ? (
        <div className="relative grid md:grid-cols-2 sm:grid-cols-1">
          {/* Background Color Overlay */}
          <div className="absolute inset-0 bg-[#f8f3e8]"></div>

          <div className="relative z-10 pt-24 pb-5 rounded-lg flex flex-col items-center bg-[#f3ebd9] ">
            <div className="">
              <h1 className="md:pb-10 pb-0 md:text-3xl text-center text-xl md:font-bold font-semibold text-[#a54c0e] uppercase md:tracking-widest">
                Event Packages
              </h1>
            </div>
            {/* Centered container for the lines and image */}

            <p className="leading-10 font-bold px-5 md:px-10">
              Discover the perfect setting for your special occasion with our
              fully customizable event packages. Tailor every detail to your
              liking, from selecting the ideal hall or space to mixing and
              matching catering options, photography services, floral
              arrangements, and decorations. Create an event that is uniquely
              yours and leaves a lasting impression on your guests.
            </p>
          </div>
          {/* Right Column */}
          <div className="relative z-10 rounded-lg flex flex-col justify-center items-center pt-10">
            <div className="">
              <img
                src={events}
                alt="events"
                className="max-w-full h-[29.68rem]"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative grid md:grid-cols-2 sm:grid-cols-1">
          {/* Right Column */}
          <div className="relative z-10 rounded-lg flex flex-col justify-center items-center pt-10 md:px-5">
            <div className="">
              <img
                src={events}
                alt="events"
                className="max-w-full h-[29.68rem]"
              />
            </div>
          </div>
          {/* Background Color Overlay */}
          <div className="absolute inset-0 bg-[#f8f3e8]"></div>

          <div className="relative z-10 pt-24 pb-5 rounded-lg flex flex-col items-center bg-[#f3ebd9] ">
            <div className="">
              <h1 className="md:pb-10 pb-0 md:text-3xl text-center text-xl md:font-bold font-semibold text-[#a54c0e] uppercase md:tracking-widest">
                Event Packages
              </h1>
            </div>
            {/* Centered container for the lines and image */}

            <p className="leading-10 font-bold px-5 md:px-10">
              Discover the perfect setting for your special occasion with our
              fully customizable event packages. Tailor every detail to your
              liking, from selecting the ideal hall or space to mixing and
              matching catering options, photography services, floral
              arrangements, and decorations. Create an event that is uniquely
              yours and leaves a lasting impression on your guests.
            </p>
          </div>
        </div>
      )}

      <AppFooter />
    </>
  );
}

export { Services };
