import React from "react";
import thumbnail1 from "../../../assets/images/first pic test.png";
import thumbnail2 from "../../../assets/images/picture two gallery.png";
import thumbnail3 from "../../../assets/images/picture three gallery.png";
import thumbnail4 from "../../../assets/images/picture four gallery.png";
import thumbnail5 from "../../../assets/images/picture five gallery.png";
import thumbnail6 from "../../../assets/images/picture six gallery.png";
import thumbnail7 from "../../../assets/images/pink heart test.png";
import thumbnail8 from "../../../assets/images/picture eight gallery.png";
import thumbnail9 from "../../../assets/images/picture nine gallery.png";
// import thumbnail10 from "../../../assets/images/picture ten gallery.png";
import thumbnail11 from "../../../assets/images/picture 11 gallery.png";
import thumbnail12 from "../../../assets/images/picture 12 gallery.png";
import thumbnail13 from "../../../assets/images/picture 13 gallery.png";
import thumbnail14 from "../../../assets/images/picture 14 gallery.png";
import thumbnail15 from "../../../assets/images/picture 15 gallery.png";
import thumbnail16 from "../../../assets/images/picture 16 gallery.png";
import thumbnail17 from "../../../assets/images/picture 17 gallery.png";
import thumbnail18 from "../../../assets/images/pink heart test.png";

import thumbnail19 from "../../../assets/images/picture 19 gallery.png";
// import thumbnail20 from "../../../assets/images/picture 20 gallery.png";
import thumbnail20 from "../../../assets/images/picture 20 gallery changed.png";
import thumbnail21 from "../../../assets/images/picture 21 gallery.png";
import thumbnail22 from "../../../assets/images/picture 22 gallery.png";
import thumbnail23 from "../../../assets/images/picture 23 gallery.png";
import thumbnail24 from "../../../assets/images/4th line fixed dimension pic.png";
import thumbnail25 from "../../../assets/images/picture 25 gallery.png";
import thumbnail26 from "../../../assets/images/picture 26 gallery.png";
import thumbnail27 from "../../../assets/images/picture 27 gallery.png";
// import thumbnail28 from "../../../assets/images/picture 28 gallery.png";
import thumbnail28 from "../../../assets/images/picture 28 gallery changed.png";
import thumbnail29 from "../../../assets/images/picture 29 gallery.png";
import thumbnail30 from "../../../assets/images/5th line fixed pic 2.png";
import thumbnail31 from "../../../assets/images/5th line fixed pic 1.png";
import thumbnail32 from "../../../assets/images/picture 32 gallery.png";
import thumbnail33 from "../../../assets/images/picture 33 gallery.png";
import thumbnail34 from "../../../assets/images/6th line 3rd pic.png";
import thumbnail35 from "../../../assets/images/picture 35 gallery.png";
import thumbnail36 from "../../../assets/images/picture 36 gallery.png";
import thumbnail37 from "../../../assets/images/picture 37 gallery.png";
import thumbnail38 from "../../../assets/images/7th line 1st pic.png";
// import thumbnail39 from "../../../assets/images/picture 39 gallery.png";
// import thumbnail40 from "../../../assets/images/picture 40 gallery.png";
import thumbnail41 from "../../../assets/images/picture 41 gallery.png";
import thumbnail42 from "../../../assets/images/picture 42 gallery.png";
import thumbnail43 from "../../../assets/images/picture 43 gallery.png";
import thumbnail44 from "../../../assets/images/picture 44 gallery.png";
import thumbnail45 from "../../../assets/images/picture 45 gallery.png";
import thumbnail46 from "../../../assets/images/8th line 1st pic.png";
import thumbnail47 from "../../../assets/images/picture 47 gallery.png";
import thumbnail48 from "../../../assets/images/picture 48 gallery.png";
import thumbnail49 from "../../../assets/images/picture 49 gallery.png";
import thumbnail50 from "../../../assets/images/picture 50 gallery.png";
// import thumbnail51 from "../../../assets/images/picture 51 gallery.png";
import thumbnail51 from "../../../assets/images/picture 51 gallery changed.png";
// import thumbnail52 from "../../../assets/images/picture 52 gallery.png";
import thumbnail52 from "../../../assets/images/picture 52 gallery changed.png";
import thumbnail53 from "../../../assets/images/picture 53 gallery.png";
import thumbnail54 from "../../../assets/images/picture 54 gallery changed.png";
import thumbnail55 from "../../../assets/images/picture 55 gallery changed.png";
// import thumbnail54 from "../../../assets/images/picture 54 gallery.png";
// import thumbnail55 from "../../../assets/images/picture 55 gallery.png";
// import thumbnail56 from "../../../assets/images/picture 56 gallery.png";

export const SliderData = [
  {
    src: thumbnail1,
    alt: "thumbnail1",
  },
  {
    src: thumbnail2,
    alt: "thumbnail2",
  },
  {
    src: thumbnail3,
    alt: "thumbnail3",
  },
  {
    src: thumbnail4,
    alt: "thumbnail4",
  },
  {
    src: thumbnail5,
    alt: "thumbnail5",
  },
  {
    src: thumbnail6,
    alt: "thumbnail6",
  },
  {
    src: thumbnail7,
    alt: "thumbnail7",
  },
  {
    src: thumbnail8,
    alt: "thumbnail8",
  },
  {
    src: thumbnail9,
    alt: "thumbnail9",
  },
  //   {
  //     src: thumbnail10,
  //     alt: "thumbnail10",
  //   },
  {
    src: thumbnail11,
    alt: "thumbnail11",
  },
  {
    src: thumbnail12,
    alt: "thumbnail12",
  },
  {
    src: thumbnail13,
    alt: "thumbnail13",
  },
  {
    src: thumbnail14,
    alt: "thumbnail14",
  },
  {
    src: thumbnail15,
    alt: "thumbnail15",
  },
  {
    src: thumbnail16,
    alt: "thumbnail16",
  },
  {
    src: thumbnail17,
    alt: "thumbnail17",
  },
  {
    src: thumbnail18,
    alt: "thumbnail18",
  },

  {
    src: thumbnail19,
    alt: "thumbnail19",
  },
  {
    src: thumbnail20,
    alt: "thumbnail20",
  },
  {
    src: thumbnail21,
    alt: "thumbnail21",
  },
  {
    src: thumbnail22,
    alt: "thumbnail22",
  },
  {
    src: thumbnail23,
    alt: "thumbnail23",
  },
  {
    src: thumbnail24,
    alt: "thumbnail24",
  },
  {
    src: thumbnail25,
    alt: "thumbnail25",
  },
  {
    src: thumbnail26,
    alt: "thumbnail26",
  },
  {
    src: thumbnail27,
    alt: "thumbnail27",
  },
  {
    src: thumbnail28,
    alt: "thumbnail28",
  },
  {
    src: thumbnail29,
    alt: "thumbnail29",
  },
  {
    src: thumbnail30,
    alt: "thumbnail30",
  },
  {
    src: thumbnail31,
    alt: "thumbnail31",
  },
  {
    src: thumbnail32,
    alt: "thumbnail32",
  },
  {
    src: thumbnail33,
    alt: "thumbnail33",
  },
  {
    src: thumbnail34,
    alt: "thumbnail34",
  },
  {
    src: thumbnail35,
    alt: "thumbnail35",
  },
  {
    src: thumbnail36,
    alt: "thumbnail36",
  },
  {
    src: thumbnail37,
    alt: "thumbnail37",
  },
  {
    src: thumbnail38,
    alt: "thumbnail38",
  },

  {
    src: thumbnail41,
    alt: "thumbnail41",
  },
  {
    src: thumbnail42,
    alt: "thumbnail42",
  },
  {
    src: thumbnail43,
    alt: "thumbnail43",
  },
  {
    src: thumbnail44,
    alt: "thumbnail44",
  },
  {
    src: thumbnail45,
    alt: "thumbnail45",
  },
  {
    src: thumbnail46,
    alt: "thumbnail46",
  },
  {
    src: thumbnail47,
    alt: "thumbnail47",
  },
  {
    src: thumbnail48,
    alt: "thumbnail48",
  },
  {
    src: thumbnail49,
    alt: "thumbnail49",
  },
  {
    src: thumbnail50,
    alt: "thumbnail50",
  },
  {
    src: thumbnail51,
    alt: "thumbnail51",
  },
  {
    src: thumbnail52,
    alt: "thumbnail52",
  },
  {
    src: thumbnail53,
    alt: "thumbnail53",
  },
  {
    src: thumbnail54,
    alt: "thumbnail54",
  },
  {
    src: thumbnail55,
    alt: "thumbnail55",
  },
];
