import React, { useState } from "react";
import { Link } from "@tanstack/react-location";
import { Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Close the mobile menu if open
    setIsOpen(false);
  };

  return (
    <>
      <nav className="bg-[#f8f3e8] mt-[.5px] md:mt-[1.5px] py-5">
        <div className="flex md:justify-center justify-start leading-4 text-[#444444] text-opacity-50 tracking-widest">
          {/* Hamburger Icon */}
          <div className="">
            <button onClick={() => setIsOpen(!isOpen)} className="md:hidden">
              <Bars3Icon className="w-6 h-6 fill-current text-[#444444] hover:text-[#a54c0e] hover:text-opacity-50" />
            </button>
          </div>
          {/* Regular Links */}
          <div className="hidden md:flex md:justify-center gap-14 md:items-center leading-4 text-[#444444] text-opacity-50 tracking-widest">
            <Link className="hover:text-[#a54c0e] hover:text-opacity-50" to="/">
              HOME
            </Link>
            <Link
              className="hover:text-[#a54c0e] hover:text-opacity-50"
              to="/services"
              onClick={handleClick} // Scroll to top on click
            >
              SERVICES
            </Link>
            <Link
              className="hover:text-[#a54c0e] hover:text-opacity-50"
              to="/gallery"
              onClick={handleClick} // Scroll to top on click
            >
              GALLERY
            </Link>
            <Link
              className="hover:text-[#a54c0e] hover:text-opacity-50"
              to="/contact"
              onClick={handleClick} // Scroll to top on click
            >
              CONTACT US
            </Link>
          </div>

          {isOpen && (
            <div className="md:hidden">
              <div className="flex flex-col gap-4 mt-4">
                <Link
                  className="hover:text-[#a54c0e] hover:text-opacity-50"
                  to="/"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  HOME
                </Link>
                <Link
                  className="hover:text-[#a54c0e] hover:text-opacity-50"
                  to="/services"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  SERVICES
                </Link>
                <Link
                  className="hover:text-[#a54c0e] hover:text-opacity-50"
                  to="/gallery"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  GALLERY
                </Link>
                <Link
                  className="hover:text-[#a54c0e] hover:text-opacity-50"
                  to="/contact"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  CONTACT US
                </Link>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
}

export { NavBar };

// import React from "react";
// import { Link } from "@tanstack/react-location";
// import { Transition } from "@headlessui/react";

// function NavBar(props) {
//   const { isScrolled } = props;
//   return (
//     <>
//       <Transition
//         show={isScrolled}
//         enter="transition-opacity duration-500"
//         enterFrom="opacity-0"
//         enterTo="opacity-100"
//         leave="transition-opacity duration-500"
//         leaveFrom="opacity-100"
//         leaveTo="opacity-0"
//       >
//         <nav className="bg-[#f8f3e8] py-5 mt-[1px] w-full fixed">
//           <div className="container mx-auto flex justify-center gap-14 leading-4 text-[#444444] text-opacity-50 tracking-widest">
//             <Link className="hover:text-[#a54c0e] hover:text-opacity-50" to="/">
//               HOME
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/services"
//             >
//               SERVICES
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/gallery"
//             >
//               GALLERY
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/about"
//             >
//               ABOUT US
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/contact"
//             >
//               CONTACT US
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/blog"
//             >
//               BLOG
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/faq"
//             >
//               FAQs
//             </Link>
//           </div>
//         </nav>
//       </Transition>
//       <Transition
//         show={!isScrolled}
//         enter="transition-opacity duration-500"
//         enterFrom="opacity-0"
//         enterTo="opacity-100"
//         leave="transition-opacity duration-500"
//         leaveFrom="opacity-100"
//         leaveTo="opacity-0"
//       >
//         <nav className="bg-[#f8f3e8] mt-[1px] w-full py-5">
//           <div className="container mx-auto flex justify-center gap-14 leading-4 text-[#444444] text-opacity-50 tracking-widest">
//             <Link className="hover:text-[#a54c0e] hover:text-opacity-50" to="/">
//               HOME
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/services"
//             >
//               SERVICES
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/gallery"
//             >
//               GALLERY
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/about"
//             >
//               ABOUT US
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/contact"
//             >
//               CONTACT US
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/blog"
//             >
//               BLOG
//             </Link>

//             <Link
//               className="hover:text-[#a54c0e] hover:text-opacity-50"
//               to="/faq"
//             >
//               FAQs
//             </Link>
//           </div>
//         </nav>
//       </Transition>
//     </>
//   );
// }

// export { NavBar };
