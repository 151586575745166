import React from "react";
import aartiHeaderLogo from "../../../assets/images/trial THREE.svg";
import aartiWordLogo from "../../../assets/images/AARTI WORD (1).svg";
import banquetWordLogo from "../../../assets/images/BANQUET WORD.svg";
import { Link } from "@tanstack/react-location";

function AppFooter() {
  return (
    <>
      <nav className="bg-[#f8f3e8] py-10 mt-5 w-full">
        <div className="flex items-center justify-center relative ">
          {/* <h1 className="text-[#a54c0e] mr-28">AARTI</h1> */}
          <img
            src={aartiWordLogo}
            alt="aartiHeaderLogo"
            className="md:h-36 h-24 absolute md:-left-2 left-7 -ml-28 -bottom-3"
            // fill="#a54c0e"
          />
          <img
            src={aartiHeaderLogo}
            alt="aartiHeaderLogo"
            className="md:h-36 h-24 mr-5"
            // fill="#a54c0e"
          />

          <img
            src={banquetWordLogo}
            alt="aartiHeaderLogo"
            className="md:h-36 h-24 absolute md:right-2 right-10 -mr-[9.3em] -bottom-2"
            // fill="#a54c0e"
          />
        </div>
        <div className=" py-14 flex justify-center gap-3 leading-4 text-[#444444] text-opacity-50 tracking-widest">
          <Link className="hover:text-[#a54c0e] hover:text-opacity-50" to="/">
            Home /
          </Link>

          <Link
            className="hover:text-[#a54c0e] hover:text-opacity-50"
            to="/services"
          >
            Services /
          </Link>

          <Link
            className="hover:text-[#a54c0e] hover:text-opacity-50"
            to="/gallery"
          >
            Gallery /
          </Link>

          <Link
            className="hover:text-[#a54c0e] hover:text-opacity-50"
            to="/contact"
          >
            Contact us
          </Link>
        </div>
        <div className="text-center leading-4 text-[#444444] text-opacity-50 tracking-widest">
          <div className="pb-10 px-2">
            <h6>
              Patna's most stunning venue for weddings and all joyous
              celebrations.
            </h6>
          </div>

          <div>
            <h6>Copyright © 2024 Aarti Banquet All Rights Reserved.</h6>
          </div>
        </div>
      </nav>
    </>
  );
}

export { AppFooter };
