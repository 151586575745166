import React, { StrictMode } from "react";

import { createRoot } from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { Router } from "@tanstack/react-location";
import { appRoutes } from "./routes/app-routes";
import { location } from "./lib/react-location";

// import { AppProvider } from "./providers/app";
const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <Router location={location} routes={appRoutes}>
      <App />
    </Router>
  </StrictMode>
);
