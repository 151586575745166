import React from "react";

function CarouselControlled({ images, startIndex, isOpen, onClose }) {
  const [currentIndex, setCurrentIndex] = React.useState(startIndex);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleClose = () => {
    onClose(); // Call the onClose function passed from the parent
  };

  if (!isOpen) {
    return null; // Render nothing if the carousel is closed
  }

  return (
    <div className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-[100vh] min-h-[60vh] md:min-h-[90vh] w-[100vw] md:w-[90vw] max-w-[500px] md:max-w-[800px] rounded-md bg-black shadow-md shadow-slate-500 focus:outline-none">
      <button
        className="absolute bottom-2 left-2 z-10 text-white w-10 h-10 bg-transparent"
        onClick={handlePrev}
      >
        Previous
      </button>
      <button
        className="absolute bottom-2 right-2 z-10 text-white w-10 h-10 bg-transparent"
        onClick={handleNext}
      >
        Next
      </button>
      <button
        className="absolute top-2 right-2 z-10 text-black w-10 h-10 bg-white"
        onClick={handleClose}
      >
        X
      </button>
      <div className="flex justify-center items-center">
        <img
          src={images[currentIndex].src}
          alt="slider data"
          style={{ objectFit: "contain", maxHeight: "100%", maxWidth: "100%" }}
        />
      </div>
    </div>
  );
}

export { CarouselControlled };

// import React, { useState } from "react";

// function CarouselControlled({ images, startIndex }) {
//   const [currentIndex, setCurrentIndex] = useState(startIndex);

//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === images.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const handleClose = () => {
//     setCurrentIndex(startIndex);
//   };

//   return (
//     <div className="relative">
//       <button
//         className="absolute top-4 right-4 z-10 text-black w-10 h-10 bg-white"
//         onClick={handleClose}
//       >
//         X {/* Replace with your close icon */}
//       </button>
//       <div className="flex justify-center items-center">
//         <button className="text-white" onClick={handlePrev}>
//           Previous
//         </button>
//         <img src={images[currentIndex].src} alt="slider data" />
//         <button className="text-white" onClick={handleNext}>
//           Next
//         </button>
//       </div>
//     </div>
//   );
// }

// export { CarouselControlled };

// // import { Carousel } from "@ark-ui/react";
// // import { useState } from "react";
// // import { XMarkIcon } from "@heroicons/react/24/outline";

// // function CarouselControlled({ images, startIndex }) {
// //   const [currentIndex, setCurrentIndex] = useState(startIndex);
// //   const [sliderActive, setSliderActive] = useState(false);

// //   const handleIndexChange = (details) => {
// //     setCurrentIndex(details.index);
// //     // setSliderActive(details.index !== null);
// //   };

// //   const handleClose = (event) => {
// //     event.stopPropagation(); // Stop event propagation
// //     // setSliderActive(false);
// //     // setCurrentIndex(startIndex); // Reset index when closing
// //   };

// //   return (
// //     <div className="relative">
// //       {/* Overlay */}
// //       <div
// //         className={`fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-90 transition-opacity ${
// //           sliderActive ? "opacity-100" : "opacity-0"
// //         }`}
// //       ></div>
// //       {/* Slider */}
// //       <Carousel.Root
// //         align="center"
// //         index={currentIndex}
// //         onIndexChange={handleIndexChange}
// //         className="fixed left-[50%] top-[50%] min-h-[10vh] md:w-[700vw] md:max-w-[700px] translate-x-[-50%] translate-y-[-50%] rounded-md bg-slate-900 shadow-md shadow-slate-500 focus:outline-none"
// //       >
// //         <button
// //           className="absolute top-4 right-4 text-black w-10 h-10 bg-white"
// //           onClick={(event) => handleClose(event)}
// //           aria-label="Close"
// //           // style={{ width: "2rem", height: "2rem" }} // Set width and height
// //         >
// //           <XMarkIcon className="w-5 h-5" />
// //         </button>
// //         <Carousel.Viewport>
// //           <Carousel.ItemGroup>
// //             {images.map((image, index) => (
// //               <Carousel.Item key={index} index={index}>
// //                 <img
// //                   src={image.src}
// //                   alt="slider data"
// //                   style={{ display: currentIndex === index ? "block" : "none" }}
// //                 />
// //               </Carousel.Item>
// //             ))}
// //           </Carousel.ItemGroup>
// //         </Carousel.Viewport>
// //         <Carousel.Control>
// //           <Carousel.PrevTrigger className="text-white">
// //             Previous
// //           </Carousel.PrevTrigger>
// //           <Carousel.NextTrigger className="text-white">
// //             Next
// //           </Carousel.NextTrigger>
// //         </Carousel.Control>
// //       </Carousel.Root>
// //     </div>
// //   );
// // }

// // export { CarouselControlled };
