// import { Navigate } from "@tanstack/react-location";
import { Home } from "../features/basics/routes/home";
import { Services } from "../features/basics/routes/services";
import { Gallery } from "../features/basics/routes/gallery";
import { About } from "../features/basics/routes/about";
import { Contact } from "../features/basics/routes/contact";
import { Blog } from "../features/basics/routes/blog";
import { Faq } from "../features/basics/routes/faq";

const appRoutes = [
  { path: "/", element: <Home /> },
  { path: "/services", element: <Services /> },
  { path: "/gallery", element: <Gallery /> },

  { path: "/contact", element: <Contact /> },
];

export { appRoutes };
