import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../components/styles.css";

import ReCAPTCHA from "react-google-recaptcha";
import { AppFooter } from "../components/app-footer";

function changeWidth(width) {
  document.body.style.width = width;
}

function changealignitems(alignItems) {
  document.body.style.alignItems = alignItems;
}
function display(display) {
  document.body.style.display = display;
}

function fontFamilies(fontFamily) {
  document.body.style.fontFamily = fontFamily;
}

window.addEventListener("load", function () {
  changeWidth("100%");
  changealignitems("center");
  display("grid");
  fontFamilies("Poppins,sans-serif");
});

function Contact() {
  const [nameField, setNameField] = useState("");

  const [emailField, setEmailField] = useState("");
  const [phoneField, setPhoneField] = useState("");
  const [subjectField, setSubjectField] = useState("");
  const [messageField, setMessageField] = useState("");

  const [isVerified, setIsVerified] = useState(false);
  function handleRecaptchaChange(value) {
    // When reCAPTCHA is verified, value will be a string
    // Otherwise, it will be null
    setIsVerified(!!value);
  }

  function submitForm(e) {
    e.preventDefault();
    if (!isVerified) {
      alert("Please verify that you are human!");
      return;
    }

    emailjs
      .sendForm(
        "service_wf8ryr9",
        "template_pnygcwh",
        e.target,
        "RRbFHd4IG9PzYshR4"
      )

      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    alert("you have successfully submitted");
    document.querySelector(".contact-formsubmit").reset();
    // reRef.current.reset();
    window.location.reload();
  }

  return (
    <div className="md:pt-20 pt-10 pb-10 md:pl-10 md:pr-10">
      <div className="bg-[#f5f5f5] grid md:grid-cols-2 grid-cols-1 md:p-16 p-8">
        {/* Column 1 with form */}
        <div>
          <h3 className="text-[#a54c0e] uppercase md:font-medium font-semibold text-2xl">
            contact the Aarti Banquet
          </h3>
          <p className="pt-6 pr-6 pb-10 text-[#989595] text-lg">
            Thank you for your interest in Aarti Banquet. Whether you are
            looking for{" "}
            <span className="font-bold text-[#555555]">pricing</span>, would
            like to{" "}
            <span className="font-bold text-[#555555]">schedule a tour</span>,
            or simply have{" "}
            <span className="font-bold text-[#555555]">questions</span>, please
            fill out the on our contact page OR Call us.
          </p>
          <div className="bg-white">
            <p className="text-right pt-1 pr-1 text-red-500">
              *Name and phone are required
            </p>
            <div className="md:px-10 pt-10 pb-10 px-1">
              <form
                onSubmit={(e) => {
                  submitForm(e);
                  return false;
                }}
                className="contact-formsubmit"
                id="dum"
              >
                <label className="uppercase font-semibold">Name:</label>
                <input
                  required
                  title="Please enter your name"
                  type="text"
                  placeholder="Please enter your name"
                  id="name"
                  className="contact-shadow w-[100%] h-[40%] mt-4 border-[1px] shadow-inner border-[#222222] mb-[20px] p-[15px]"
                  name="name"
                  value={nameField}
                  onChange={(e) => setNameField(e.target.value)}
                />
                {/* <span class="absolute top-0 left-0 w-2 h-2 bg-gray-900 shadow-md"></span> */}
                <label className="uppercase font-semibold">Email:</label>
                <input
                  type="text"
                  placeholder="Email"
                  id="email"
                  className="contact-shadow w-[100%] h-[40%] mt-4 border-[1px] shadow-inner border-[#222222] mb-[20px] p-[15px]"
                  name="email"
                  value={emailField}
                  onChange={(e) => setEmailField(e.target.value)}
                />
                <label className="uppercase font-semibold">Phone:</label>
                <input
                  required
                  pattern="(6|7|8|9)\d{9}$"
                  title="Please enter valid phone number"
                  type="text"
                  placeholder="Your valid phone number"
                  id="phone"
                  className="contact-shadow w-[100%] h-[40%] mt-4 border-[1px] shadow-inner border-[#222222] mb-[20px] p-[15px]"
                  name="phone"
                  value={phoneField}
                  onChange={(e) => setPhoneField(e.target.value)}
                />
                <label className="uppercase font-semibold">subject:</label>
                <input
                  type="text"
                  placeholder="Subject"
                  id="subject"
                  className="contact-shadow w-[100%] h-[40%] mt-4 border-[1px] shadow-inner border-[#222222] mb-[20px] p-[15px]"
                  name="subject"
                  value={subjectField}
                  onChange={(e) => setSubjectField(e.target.value)}
                />
                <label className="uppercase font-semibold">Description:</label>
                <textarea
                  placeholder="Message"
                  id="message"
                  className="contact-shadow w-[100%] h-[40%] mt-4 border-[1px] shadow-inner border-[#222222] mb-[20px] p-[15px]"
                  cols="30"
                  rows="4"
                  name="message"
                  value={messageField}
                  onChange={(e) => setMessageField(e.target.value)}
                ></textarea>

                <ReCAPTCHA
                  onChange={handleRecaptchaChange}
                  sitekey="6LeF6nopAAAAAAGIj60DYDKB_9t-bFIrb9CmiC4l"
                  className=""
                />
                <button
                  type="submit"
                  className="bg-[#a44e24] hover:bg-slate-700 mt-6 text-white py-2 px-4 uppercase tracking-widest text-xl transition duration-300 ease-in-out"
                  value="Refresh"
                  // onClick={() => window.location.reload()}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* COLUMN 2 */}
        <div className="md:p-10 pt-20">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3599.423643281819!2d85.1470387508338!3d25.557568683649876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f2a7f6af736b8f%3A0xf80c006d357afdd3!2sAarti%20Banquet!5e0!3m2!1sen!2sus!4v1631493830674!5m2!1sen!2sus"
            // width="100%"
            // height="auto"
            // style={{ border: "0" }}
            className="w-[100%] h-96"
            title="contact"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <div className="w-[75%] mt-20">
            <h4 className="uppercase font-medium tracking-widest text-2xl leading-9">
              aarti banquet
            </h4>
            <div className="text-slate-500 mb-5 text-lg">
              <h5>Near Shri Ram Centennial School</h5>
              <h5>Jaganpura - Chipura Rd, Near Sahpur,</h5>
              <h5>Bhogipur, Patna, Ektapuram,</h5>
              <h5>Bihar 804453, India</h5>
            </div>
            <h5 className="font-medium">
              <span className="font-semibold">Primary Phone:</span> 9304506792{" "}
              <br /> <span className="font-semibold">Secondary Phone:</span>{" "}
              9431622819
            </h5>
          </div>
        </div>
      </div>
      {/* <div className="contact-in">
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3599.423643281819!2d85.1470387508338!3d25.557568683649876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f2a7f6af736b8f%3A0xf80c006d357afdd3!2sAarti%20Banquet!5e0!3m2!1sen!2sus!4v1631493830674!5m2!1sen!2sus"
            width="100%"
            height="auto"
            style={{ border: "0" }}
            title="contact"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact-form">
          <h1>Contact Us</h1>
          <h4>
            Phone: <br /> Primary: 9304506792 <br /> Secondary: 9431622819
          </h4>
          <p>
            {" "}
            Please contact us by calling or send a message. We will get back to
            you within 24 hours.
          </p>

          <form
            onSubmit={(e) => {
              submitForm(e);
              return false;
            }}
            className="contact-formsubmit"
            id="dum"
          >
            <input
              required
              title="Please enter your name"
              type="text"
              placeholder="Please enter your name"
              id="name"
              className="contact-form-txt"
              name="name"
              value={nameField}
              onChange={(e) => setNameField(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              id="email"
              className="contact-form-txt"
              name="email"
              value={emailField}
              onChange={(e) => setEmailField(e.target.value)}
            />
            <input
              required
              pattern="(6|7|8|9)\d{9}$"
              title="Please enter valid phone number"
              type="text"
              placeholder="Your valid phone number"
              id="phone"
              className="contact-form-txt"
              name="phone"
              value={phoneField}
              onChange={(e) => setPhoneField(e.target.value)}
            />
            <input
              type="text"
              placeholder="Subject"
              id="subject"
              className="contact-form-txt"
              name="subject"
              value={subjectField}
              onChange={(e) => setSubjectField(e.target.value)}
            />
            <textarea
              placeholder="Message"
              id="message"
              className="contact-form-textarea"
              cols="30"
              rows="10"
              name="message"
              value={messageField}
              onChange={(e) => setMessageField(e.target.value)}
            ></textarea>

            <ReCAPTCHA
              onChange={handleRecaptchaChange}
              sitekey="6LeF6nopAAAAAAGIj60DYDKB_9t-bFIrb9CmiC4l"
            />
            <button
              type="submit"
              className="contact-form-btn mt-5 rounded-lg"
              value="Refresh"
              // onClick={() => window.location.reload()}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div></div> */}
      <AppFooter />
    </div>
  );
}
export { Contact };
