import React from "react";
// import aartiHeaderLogo from "../../../assets/images/version FOUR.svg";
// import aartiHeaderLogo from "../../../assets/images/Version FIVE.svg";
import aartiHeaderLogo from "../../../assets/images/trial THREE.svg";
import aartiWordLogo from "../../../assets/images/AARTI WORD (1).svg";
import banquetWordLogo from "../../../assets/images/BANQUET WORD.svg";

// import aartiHeaderLogo from "../../../assets/images/version EIGHT.svg";

function Header() {
  return (
    <nav className="bg-[#f8f3e8]">
      <div className="flex items-center justify-center relative ">
        <img
          src={aartiWordLogo}
          alt="aartiHeaderLogo"
          className="md:h-36 h-24 absolute md:left-0 left-7 -ml-28 -bottom-3"
          // fill="#a54c0e"
        />
        <img
          src={aartiHeaderLogo}
          alt="aartiHeaderLogo"
          className="md:h-36 h-24 md:mr-0 mr-5"
          // fill="#a54c0e"
        />

        <img
          src={banquetWordLogo}
          alt="aartiHeaderLogo"
          className="md:h-36 h-24 absolute md:right-0 -right-6 md:-mr-[9.3em] -mr-[4.6em] -bottom-2"
          // fill="#a54c0e"
        />
      </div>
    </nav>
  );
}

export { Header };

// import React from "react";
// // import aartiHeaderLogo from "../../../assets/images/version FOUR.svg";
// // import aartiHeaderLogo from "../../../assets/images/Version FIVE.svg";
// import aartiHeaderLogo from "../../../assets/images/trial THREE.svg";
// import aartiWordLogo from "../../../assets/images/AARTI WORD (1).svg";
// import banquetWordLogo from "../../../assets/images/BANQUET WORD.svg";

// // import aartiHeaderLogo from "../../../assets/images/version EIGHT.svg";

// function Header(props) {
//   const { isScrolled } = props;

//   return (
//     !isScrolled && (
//       <nav className="bg-[#f8f3e8] py-4 w-full">
//         <div className="container mx-auto flex items-center justify-center relative ">
//           {/* <h1 className="text-[#a54c0e] mr-28">AARTI</h1> */}
//           <img
//             src={aartiWordLogo}
//             alt="aartiHeaderLogo"
//             className="h-36 absolute left-0 -ml-28 -bottom-3"
//             // fill="#a54c0e"
//           />
//           <img
//             src={aartiHeaderLogo}
//             alt="aartiHeaderLogo"
//             className="h-36"
//             // fill="#a54c0e"
//           />

//           <img
//             src={banquetWordLogo}
//             alt="aartiHeaderLogo"
//             className="h-36 absolute right-0 -mr-[9.3em] -bottom-2"
//             // fill="#a54c0e"
//           />
//           {/* <h1 className="text-[#a54c0e] ml-28">BANQUET</h1> */}
//         </div>
//       </nav>
//     )
//   );
// }

// export { Header };
