import { Header } from "./features/basics/components/header";
import { NavBar } from "./features/basics/components/navbar";
import { Outlet } from "@tanstack/react-location";
import { AppFooter } from "./features/basics/components/app-footer";
import { useEffect, useState } from "react";
import { Home } from "./features/basics/routes/home";
import { appRoutes } from "./routes/app-routes";

function App() {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setIsHeaderVisible(window.scrollY < 100);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  return (
    <div className="overflow-x-clip">
      <Header />
      <NavBar />
      {/* <Header />
      <NavBar /> */}
      {/* <div
        className={`${
          isHeaderVisible ? "" : "hidden"
        } fixed top-0 w-full z-50 transition-all duration-300`}
      >
        <Header />
      </div> */}

      {/* <div className="z-20 top-[143px] fixed w-full">
        <NavBar />
      </div> */}

      {/* <div
        className={`${
          isHeaderVisible ? "md:top-[143px] top-[97px] fixed w-full" : ""
        } fixed top-0 w-full z-50 transition-all duration-0`}
      >
        <NavBar />
      </div> */}

      <main className="">
        <Outlet routes={appRoutes} />
      </main>
      {/* <AppFooter /> */}
    </div>
  );
}

export { App };

// import { Header } from "./features/basics/components/header";
// import { NavBar } from "./features/basics/components/navbar";
// import { Outlet } from "@tanstack/react-location";
// import { AppFooter } from "./features/basics/components/app-footer";
// import { useEffect, useState } from "react";

// function App() {
//   const [isScrolled, setIsScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsScrolled(true);
//       } else {
//         setIsScrolled(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
//   return (
//     <div>
//       {/* <Header />
//       <NavBar /> */}
//       <Header isScrolled={isScrolled} />
//       <NavBar isScrolled={isScrolled} />

//       {/* {!isScrolled && <NavBar />} */}

//       <main>
//         <Outlet />
//       </main>
//       <AppFooter />
//     </div>
//   );
// }

// export { App };
