import React from "react";
import award from "../../../assets/images/Aarti Banquet (2).jpg";
// import trail3 from "../../../assets/images/trial THREE.svg";
import trail3 from "../../../assets/images/aarti banquet logo PNG.png";
import handPic from "../../../assets/images/hand.jpg";
import invitationRose from "../../../assets/images/rose-invitation.png";
import cake from "../../../assets/images/wedding-cake.png";
import garden from "../../../assets/images/garden pic for home page.jpg";
import gallaeryMarriage from "../../../assets/images/front page gallery marriage photo.png";
import frontWeddingPic from "../../../assets/images/front wedding pic.png";
import aboutusbanner from "../../../assets/images/about us front banner real deal.png";
import { Link } from "@tanstack/react-location";
import { AppFooter } from "../components/app-footer";

function Home() {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Close the mobile menu if open
    // setIsOpen(false);
  };

  return (
    <>
      <div className="">
        <img className="h-auto" src={aboutusbanner} alt="after header" />
      </div>
      {/* Two Columns */}
      <div className="relative grid md:grid-cols-2 sm:grid-cols-1">
        {/* Background Color Overlay */}
        <div className="absolute inset-0 bg-[#f8f3e8]"></div>

        <div className="relative z-10 pt-24 pb-5 rounded-lg flex flex-col items-center bg-[#f3ebd9] ">
          <div className="">
            <h1 className="md:pb-10 pb-0 mb-0 md:text-3xl text-center text-xl md:font-bold font-semibold text-[#a54c0e] uppercase md:tracking-widest">
              Welcome to aarti banquet
            </h1>
          </div>
          {/* Centered container for the lines and image */}
          <div className="flex flex-row md:-mt-5 md:mb-56 mb-64 mt-5 ">
            {/* First line */}
            {/* <div className="md:w-28 w-10 h-0.5 bg-[#444444] bg-opacity-40 md:-mt-52"></div> */}
            {/* Image */}
            <div>
              <img
                src={trail3}
                alt="aarti banquet home"
                className="md:w-[15rem] md:h-[13rem] w-full h-auto max-w-[15rem] max-h-[13rem]"
                // className="md:-mr-0 md:-ml-0 md:-mt-56 md:mb-10 mb-0"
              />
            </div>

            {/* Second line */}
            {/* <div className="md:w-28 w-10 h-0.5 bg-[#444444] bg-opacity-40 -mt-64 md:-mt-52"></div> */}
          </div>

          <p className="leading-10 font-bold md:-mt-52 -mt-60 px-5 md:px-10">
            Welcome to the grand Aarti Banquet – your ultimate venue for
            weddings, ceremonies, and business events! With several spacious
            rooms, multiple bathrooms, and versatile halls, we provide ample
            space for your needs. Elevate your occasion with our exquisite
            decorations, floral arrangements, and top-notch catering. Our
            outdoor garden offers a picturesque backdrop, and customizable
            seating ensures guest comfort. With a capacity for up to{" "}
            <span className=" text-black">
              1500 guests and luxurious accommodations for 150 people
            </span>
            , we're renowned for exceptional event hosting. Experience
            hospitality at its finest at the grand Aarti Banquet, where every
            detail is curated for an unforgettable event.
          </p>
          <Link
            className="hover:text-[#a54c0e] hover:text-opacity-50"
            to="/services"
            onClick={handleClick}
          >
            <button className="bg-[#a54c0e] hover:bg-slate-700 text-white mt-6 font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
              Services
            </button>
          </Link>
        </div>

        {/* Right Column */}
        <div className="relative z-10 rounded-lg flex flex-col justify-center items-center pt-10">
          <div className="">
            <img src={award} alt="award" className="max-w-full h-[29.68rem]" />
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.google.com/maps/place/Aarti+Banquet/@25.5562217,85.1468363,15z/data=!4m8!3m7!1s0x39f2a7f6af736b8f:0xf80c006d357afdd3!8m2!3d25.5562217!4d85.1468363!9m1!1b1!16s%2Fg%2F11h3_76xw3?entry=ttu"
          >
            <button
              className="bg-[#a44e24] hover:bg-slate-700 mt-10 mb-10 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
              onClick={handleClick}
            >
              See More
            </button>
          </a>
        </div>
      </div>

      <div class="container md:flex">
        {/* Left Column */}
        <div class="md:w-[40%] sm:w-auto">
          <img src={cake} alt="Cake" class="h-[29.69rem] md:h-auto" />
        </div>
        {/* <!-- Right Column --> */}
        <div class="md:w-3/4 sm:w-auto bg-white rounded-lg md:pl-20 flex justify-center items-center">
          <div class="flex flex-col items-center justify-center">
            <h2 class="text-lg font-semibold mb-4 uppercase mt-10 pl-5">
              You're invited! Come meet with us.
            </h2>
            <h4 className="font-serif text-[#a44e24] pl-5">
              Visit us and share what you need for your banquet hall.
            </h4>

            <div className="w-[21.875rem]">
              <img
                src={invitationRose}
                alt="Small"
                class="h-[21.875rem] mt-8 mb-4"
              />
            </div>
            <Link
              className="hover:text-[#a54c0e] hover:text-opacity-50"
              to="/contact"
              onClick={handleClick}
            >
              <div className="md:mb-1 mb-5">
                <button className="bg-[#a44e24] hover:bg-slate-700 mt-6 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                  Contact Us
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="md:flex sm:flex-none md:pt-0 pt-10 bg-[#f8f3e8] items-center justify-center md:h-56 h-auto md:pb-5 pb-10">
          {/* Left column for address */}
          <div className="w-[75%] pl-20 ">
            <h4 className="uppercase text-[#444444] font-bold">
              Directions to aarti banquet
            </h4>
            <h5>Near Shri Ram Centennial School</h5>
            <h5>Jaganpura - Chipura Rd, near Sahpur,</h5>
            <h5>Bhogipur, Patna, Ektapuram,</h5>
            <h5>Bihar 804453, India</h5>
          </div>
          <div className="md:w-[25%] flex justify-center items-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/maps/place/Aarti+Banquet/@25.5562217,85.1468363,15z/data=!4m6!3m5!1s0x39f2a7f6af736b8f:0xf80c006d357afdd3!8m2!3d25.5562217!4d85.1468363!16s%2Fg%2F11h3_76xw3?entry=ttu"
            >
              <button
                className="relative uppercase border border-[#a54c0e] text-[#a54c0e] mt-6 py-2 px-7 md:px-4 md:mr-20 mr-0 rounded-full overflow-hidden transition duration-300 ease-in-out group"
                onClick={handleClick}
              >
                Navigate to aarti banquet
                <span className="absolute inset-0 bg-[#f8f3e8] w-full h-0 bottom-0 group-hover:bottom-full group-hover:h-full transition-all duration-500 ease-in-out group-hover:bg-[#a54c0e]"></span>
                <span className="absolute inset-0 flex items-center justify-center group-hover:text-white">
                  Navigate to aarti banquet
                </span>
              </button>
            </a>
          </div>
        </div>
        {/* Red lines */}
        <div className="absolute top-0 left-0 w-full h-0.5 bg-[#a54c0e]"></div>
        <div className="absolute top-3 left-0 w-full h-0.5 bg-[#a54c0e]"></div>
        <div className="absolute left-0 bottom-0 w-full h-0.5 bg-[#a54c0e]"></div>

        <div className="absolute bottom-3 left-0 w-full h-0.5 bg-[#a54c0e]"></div>
      </div>

      {/* Schedule tour / call us */}

      <div className="md:relative md:py-20 md:pl-20 md:pr-56 mt-10">
        <img
          src={frontWeddingPic}
          alt="beautiful marriage"
          className="w-full h-auto"
        />
        <div className="md:pl-24 md:top-[25%] md:right-0 md:absolute">
          <div className="md:h-[25rem] md:w-[25rem] bg-white shadow-2xl pb-5">
            <div className="items-center justify-center px-10 pt-16">
              <div className="">
                <h3 className="uppercase text-[#a54c0e]">schedule a tour</h3>
              </div>
              <div className="h-0.5 bg-[#444444] w-10 mt-10"></div>
              <div className="mt-10">
                <p>
                  Thank you for your interest in Aarti Banquet. Whether you are
                  looking for pricing, would like to schedule a tour, or simply
                  have questions, please fill out the form on our contact page
                  or call us.
                </p>
              </div>
              <div>
                <Link
                  className="hover:text-[#a54c0e] hover:text-opacity-50"
                  to="/contact"
                  onClick={handleClick}
                >
                  <button className="relative uppercase border border-[#a54c0e] text-[#a54c0e] mt-6 font-bold py-2 px-4 rounded-full overflow-hidden transition duration-300 ease-in-out group">
                    CALL US
                    <span className="absolute inset-0 bg-[#f8f3e8] w-full h-0 bottom-0 group-hover:bottom-full group-hover:h-full transition-all duration-500 ease-in-out group-hover:bg-[#a54c0e]"></span>
                    <span className="absolute inset-0 flex items-center justify-center group-hover:text-white">
                      CALL US
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:h-[40.625rem] sm:h-auto bg-cats bg-no-repeat">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="md:pl-24 md:pt-20 pl-4 pt-4">
            <div className="h-[31.25] bg-transparent shadow-2xl pb-10">
              <div className="items-center justify-center md:px-10 px-16 md:pt-16 pt-36">
                <h3 className="uppercase">Gallery</h3>

                <div className="h-0.5 bg-[#a54c0e] mt-5"></div>
                <div className="md:mt-10 mt-10">
                  <p>
                    Aarti Banquet is Patna's top choice for a stunning wedding
                    venue, offering both indoor and outdoor settings for your
                    special day. With its picturesque charm and exceptional
                    service, it's the perfect place for an unforgettable
                    celebration.
                  </p>
                </div>
                <div>
                  <Link
                    className="hover:text-[#a54c0e] hover:text-opacity-50"
                    to="/gallery"
                    onClick={handleClick}
                  >
                    <button className="relative uppercase border border-[#a54c0e] text-[#a54c0e] mt-6 font-bold py-2 px-4 rounded-full overflow-hidden transition duration-300 ease-in-out group">
                      VIEW GALLERY
                      <span className="absolute inset-0 bg-[#f8f3e8] w-full h-0 bottom-0 group-hover:bottom-full group-hover:h-full transition-all duration-500 ease-in-out group-hover:bg-[#a54c0e]"></span>
                      <span className="absolute inset-0 flex items-center justify-center group-hover:text-white">
                        VIEW GALLERY
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Right column */}
          <div className="flex md:-mt-2">
            {/* Apply consistent styling to both images */}
            <div className="relative md:mt-28 mt-20 z-10 pl-28">
              {/* Set max-width and max-height to prevent excessive stretching */}
              <img
                src={gallaeryMarriage}
                alt="Gallery Marriage"
                className="md:w-[25rem] md:h-[31.25rem] w-full h-auto max-w-[25rem] max-h-[31.25rem]"
              />
            </div>
            {/* Apply consistent styling to both images */}
            <div className="relative mr-28 mt-14 -ml-20">
              {/* Set max-width and max-height to prevent excessive stretching */}
              <img
                src={garden}
                alt="Garden"
                className="md:w-[25rem] md:h-[31.25rem] w-full h-auto max-w-[25rem] max-h-[31.25rem]"
              />
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}

export { Home };
