import React, { useState } from "react";
import galleryBannerPic from "../../../assets/images/picture 21 for banner in gallery section.png";
// import thumbnail11 from "../../../assets/images/picture one aarti banquet.png";
import { SliderData } from "../components/sliderData";

import { CarouselControlled } from "../components/carousal";
import { AppFooter } from "../components/app-footer";

function Gallery() {
  const [showSlider, setShowSlider] = useState(false);
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(null);

  const handleThumbnailClick = (index) => {
    setSelectedThumbnailIndex(index);
    setShowSlider(true);
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setShowSlider(false);
    setSelectedThumbnailIndex(null);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <div className="">
        <img
          className="h-auto"
          src={galleryBannerPic}
          alt="Gallery Header of building"
        />
      </div>

      <div
        className={`bg-[#f5f5f5f5] md:w-[95%] md:ml-8 ml-0 ${
          showSlider
            ? "md:w-full md:h-full bg-black bg-opacity-95 transition-opacity"
            : ""
        }`}
      >
        {/* <div className="bg-[#f5f5f5f5] w-[95%]"> */}
        <div className="pt-10 md:pl-20 pb-10 pl-10">
          <h3 className="uppercase md:font-medium font-semibold">Wedding</h3>
          <h6 className="text-gray-500">View some of our photos below.</h6>
        </div>

        <div
          className={
            "grid grid-cols-1 md:grid-cols-6 md:mr-24 md:ml-10 mr-10 ml-10 gap-3"
          }
        >
          {SliderData.map((thumbnail, index) => (
            <div key={index} className={`md:ml-10 overflow-hidden relative`}>
              <img
                src={thumbnail.src}
                alt={`Thumbnail ${index}`}
                className={`cursor-pointer object-cover md:max-h-[100px] max-h-[150px] w-[100%]`}
                onClick={() => handleThumbnailClick(index)}
              />
              {/* Add a semi-transparent overlay */}
              {showSlider && (
                <div className="absolute inset-0 bg-black opacity-75"></div>
              )}
            </div>
            // </div>
          ))}
          {showSlider && (
            <div className="">
              <CarouselControlled
                images={SliderData}
                startIndex={selectedThumbnailIndex}
                onClose={handleClose}
                isOpen={showSlider}
              />
            </div>
          )}
        </div>
      </div>
      {showSlider ? "" : <AppFooter />}
    </>
  );
}

export { Gallery };
